let isModalVisible = false;

const $modalElementAddress = document.getElementById("modal-address");

const modalOptionsAddress = {
    placement: "top-center",
    backdrop: "static",
    backdropClasses: "bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40",
    onHide: () => {
        if (isModalVisible) {
            Livewire.dispatch('close-modal-address-outside');
            isModalVisible = false;
        }
    },
    onShow: () => {
        isModalVisible = true;
    },
    onToggle: () => {
        /* if (isModalVisible) {
            Livewire.dispatch('close-modal-address-outside');
            isModalVisible = false;
        } */
    },
};
const instanceOptionsModalAddress = {
    id: "modal-address",
    override: true,
};

new Modal(
    $modalElementAddress,
    modalOptionsAddress,
    instanceOptionsModalAddress
);
