let isModalVisible = false;

const $modalElementCompanyTime = document.getElementById("modal-company-time");

const modalOptionsCompanyTime = {
    placement: "top-center",
    backdrop: "static",
    backdropClasses: "bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40",
    onHide: () => {
        if (isModalVisible) {
            Livewire.dispatch('close-modal-company-time');
            isModalVisible = false;
        }
    },
    onShow: () => {
        isModalVisible = true;
    },
    onToggle: () => {
        /* if (isModalVisible) {
            Livewire.dispatch('close-modal-company-time');
            isModalVisible = false;
        } */
    },
};
const instanceOptionsModalCompanyTime = {
    id: "modal-company-time",
    override: true,
};

new Modal(
    $modalElementCompanyTime,
    modalOptionsCompanyTime,
    instanceOptionsModalCompanyTime
);
